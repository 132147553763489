<template>
	<div>
		<div v-if="!is_show_chats">
			<a id="chat_icon" @click="showChats()" class="d-flex align-center justify-center">
				<img :src="require('../assets/images/Path.png')" alt />
			</a>

			<div id="chat_count" class="d-flex align-center justify-center">
				<div>
					<!-- <p class="ls-n2"> -->
					<p>
						99
					</p>
				</div>
			</div>
		</div>

		<chat-haggle :is_show="is_show_chats" @showChatIcon="showChatIcon()"></chat-haggle>
	</div>
</template>

<script>
const ChatHaggle = () => import('../mycomponents/ChatHaggle.vue')
export default {
	props: {},

	components: {
		ChatHaggle: ChatHaggle
	},

	data() {
		return {
			is_show_chats: false
		}
	},

	methods: {
		showChats() {
			this.is_show_chats = true
		},

		showChatIcon() {
			this.is_show_chats = false
		}
	}
}
</script>

<style lang="scss">
#chat_icon {
	// display: inline-block;
	background-color: #2974ff;
	width: 52px;
	height: 52px;
	text-align: center;
	border-radius: 52px;
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
	z-index: 1000;

	img {
		width: 16px;
	}
}
// #button::after {
// 	content: '\f077';
// 	font-family: FontAwesome;
// 	font-weight: normal;
// 	font-style: normal;
// 	font-size: 2em;
// 	line-height: 50px;
// 	color: #fff;
// }

#chat_count {
	content: '';
	width: 25px;
	height: 25px;
	border-radius: 25px;
	background-color: #fff;
	text-align: center;
	position: fixed;
	bottom: 78px;
	right: 34px;
	transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
	z-index: 1000;

	div {
		width: 20px;
		height: 20px;
		border-radius: 20px;
		background-color: #f15757;

		p {
			font-size: 12px;
			font-weight: bold;
			color: #fff;
			margin: 0;
			font-family: 'Inter-SemiBold';
			padding-top: 1px;
		}
	}

	.ls-n2 {
		letter-spacing: -2px;
	}
}
</style>
